import * as React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import * as styles from "src/scss/_products.module.scss"

const ProductsPage = () => (
  <Layout>
    <Seo title="製品概要" />
    <div className="pageWrapper">
      <div className="pageTtl">
        <h1>製品情報</h1>
      </div>

      <h2 className={styles.h2Ttl}>インフォ・ザ・伝票</h2>
      <p className="productMsg">
        インフォ・ザ・伝票は、専用伝票などの記入項目をドットインパクトプリンターで印刷するソフトです。<br />
        運送会社の配送伝票、特殊な納品書、産廃マニフェスト伝票などあらゆる伝票類を簡単に作成することができます。
      </p>
      <p className="btnLarge">
        <a href="/products/info-invoice/">インフォ・ザ・伝票 について詳しく見る
          <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px" ><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
        </a>
      </p>

      <h3 className={styles.h3Ttl}>インフォ・ザ・伝票試用版ダウンロード</h3>
      <p className="productMsg">
        30日間期間限定の試用版をご用意しております。こちらからダウンロードしてお試し下さい。<br />
        インストール後30日間は機能の制限無くお使いいただけます。
      </p>

      <div className="btnLarge">
        <a href="/products/infoinvoice-dl/">試用版ダウンロードはこちら
          <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px" ><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
        </a>
      </div>

      <h3 className={styles.h3Ttl}>インフォ・ザ・伝票に関するお問い合せ</h3>
      <p className="productMsg">
        お気軽に以下の窓口までお問い合わせください。
      </p>

      <div className="contactWrapper">
        <p className="companyName">リンクチャネル(株)長岡CSセンター</p>
        <p className="telNum">
          <a href="tel:0258-25-6320">お問い合せ専用電話：
            <span>0258-25-6320</span>
          </a>
        </p>
        <p>受付時間：弊社休業日、祭日を除く月曜-金曜の10:00 ~ 17:00</p>
        <p className="telNum">
          <a href="mailto:invoice@renk.co.jp">
            E-Mail：invoice@renk.co.jp
          </a>
        </p>
        <p>Fax：0258-25-6309</p>
      </div>

      <hr className={styles.brdBlue} />

      <h2 className={styles.h2Ttl}>Info-POS</h2>
      <p className="productMsg">
        Info-POSは主に物販向けの汎用POSレジソフトです。<br />
        複数レジ対応、多店舗対応、自動釣銭機対応など、豊富なオプションもご用意しております。
      </p>

      <div className="btnLarge">
        <a href="/products/info-pos/">Info-POS について詳しく見る
          <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px" ><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
        </a>
      </div>

      <h3 className={styles.h3Ttl}>Info-POSに関するお問い合せ</h3>
      <p className="productMsg">
        お気軽に以下の窓口までお問い合わせください。
      </p>

      <div className="contactWrapper">
        <p className="companyName">リンクチャネル(株)長岡CSセンター</p>
        <p className="telNum">
          <a href="tel:0258-25-6320">お問い合せ専用電話：
            <span>0258-25-6320</span>
          </a>
        </p>
        <p>受付時間：弊社休業日、祭日を除く月曜-金曜の10:00 ~ 17:00</p>
        <p className="telNum">
          <a href="mailto:pos@renk.co.jp">
            E-Mail：pos@renk.co.jp
          </a>
        </p>
        <p>Fax：0258-25-6309</p>
      </div>
    </div>
  </Layout>
)

export default ProductsPage
